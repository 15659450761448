import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { setWithExpiry } from '../pages/checkAndSetLoginExpried';
import '../pages/Login.css';

const FeedbackManage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const message = searchParams.get('message');
  const userUuid = searchParams.get('userUuid');
  const logoRef = useRef(null);
  const formRef = useRef(null);

  const fetchedData = useCallback(async () => {
    try {
      if (userUuid) {
        // await axios.post(weburls.Free_Plan_Expire_Feedback, body);
        setWithExpiry('feedbackUserUuid', userUuid, 30 * 60 * 1000);
        setWithExpiry('feedbackMessage', message, 30 * 60 * 1000);
        if (message === 'Free trial wasn’t long enough') {
          setTimeout(() => navigate(`/feedbackManage1`), 1000);
        }
        if (message === 'I need more features') {
          setTimeout(() => navigate(`/feedbackManage2`), 1000);
        }
        if (message === 'Customer service was less then expected') {
          setTimeout(() => navigate(`/feedbackManage3`), 1000);
        }
        if (message === 'Ease of use was less then expected') {
          setTimeout(() => navigate(`/feedbackManage4`), 1000);
        }
        if (message === 'I found an alternative') {
          setTimeout(() => navigate(`/feedbackManage5`), 1000);
        }
        if (message === 'Lack of integration providers') {
          setTimeout(() => navigate(`/feedbackManage6`), 1000);
        }
        if (message === 'Other') {
          setTimeout(() => navigate(`/feedbackManage7`), 1000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [userUuid]);

  useEffect(() => {
    fetchedData();
  }, [fetchedData]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return <></>;
};

export default FeedbackManage;
