import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Arrow } from '../../assests/images/icons/edit.svg';
import Profile from '../../assests/images/icons/image.png';
import Mortgage from '../../assests/images/icons/Mortgage.png';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';
const ToggleSwitch = ({ accountId, value, onChange }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={() => onChange(accountId)} />
      <span className="slider round"></span>
    </label>
  );
};
const CashFlow = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();
  const [apiData, setApiData] = useState([]);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [toggleStates, setToggleStates] = useState({});

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        setApiData(response?.data?.accounts);
        localStorage.setItem('accountUuid', JSON.stringify(response?.data?.accounts));
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);

  function formatAmount(amount) {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  }

  const toggleLightMode = (accountId) => {
    const ToggleValue = !toggleStates[accountId];
    console.log(ToggleValue, '---', accountId, '----');
    setToggleStates((prevToggleStates) => {
      return {
        ...prevToggleStates,
        [accountId]: !prevToggleStates[accountId],
      };
    });
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer edit-cashflow-container-main">
              <div className="row top-nav">
                <div className="col-md-4 edit-cashflow-title-heading">
                  <div className="bold big-head-new">Select your spendings account</div>
                  <div className="fst-normal setting_text_color">
                    Select one or more accounts that are used for general everyday spending.
                  </div>
                </div>
                <div className="col-md-8 right-section back-portal-edit-cashflow">
                  <div className=" right-section">
                    <div className="edit-btn" style={{ width: '327px', height: '60px', marginRight: '30px' }}>
                      <div className="left">
                        <h4>Back to your Cashflow</h4>
                        <p style={{ color: '#04baff' }}>
                          <Link to={`/CashFlow/${uuid}`}>Make any needed changes.</Link>
                        </p>
                      </div>

                      <div className="right">
                        <a href="#">
                          <Arrow />
                        </a>
                      </div>
                    </div>
                    <div className="profile-dashboard">
                      <img src={Shape} alt="Mortage_img" onClick={togglePopupInfo} style={{ width: '20px' }} />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Cashflow 🤑"
                        description={[
                          'Your cashflow is determined by the data you enter in your budget. Based on how often you get paid or spend money, this shows when either of these two will occur next in the future',
                          'You can change views for your cash flow to show weekly, monthly, or quarterly incomings and outgoings.',
                          'Review your balance left over per week, month, or quarter. If it’s in the negative, make some adjustments. If it’s in green, then you’re good to go.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-xl-8 col-lg-4 col-md-6 acccount-balances-wrappper-left-side">
                  <div className="bold black account-bal-title">Your accounts</div>
                  {apiData?.length > 0 ? (
                    apiData?.map((item) => (
                      <div className="merchant-box-parent mt-3">
                        <div
                          className="merchant-box "
                          style={{
                            boxShadow: ' 0px 5px 30px 0px #0000001A',
                          }}
                        >
                          <div className="d-flex">
                            <div className="your-account-balance-wrp-logo-img">
                              <img src={item.avatar === '' || item.avatar === null ? Mortgage : item.avatar} alt="" />
                            </div>
                            {item.akahuAccountNumber ? (
                              <div className="ms-3">
                                <div className="account-box fw-bold-600">{item.accountName}</div>

                                <div className="number-account inter">{item.akahuAccountNumber}</div>
                              </div>
                            ) : (
                              <div className="ms-3" style={{ display: 'inline-flex' }}>
                                <div className="account-box fw-bold-600">{item.accountName}</div>
                              </div>
                            )}
                          </div>
                          <div className={`d-flex ${item.value < 0 ? 'light-red-trans' : 'light-green-trans'}`}>
                            <h4 className="account-balance">{formatAmount(item.value ?? 0)}</h4>
                            <ToggleSwitch
                              accountId={item.accountUuid}
                              value={toggleStates[item.accountUuid] || true}
                              onChange={toggleLightMode}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="merchant-box mt-3">No data found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CashFlow;
